<script lang="ts">
import { getFirebaseBackend } from "@/authUtils";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { authComputed } from "@/state/helpers";
import { WorkspaceService } from "@claimsgate/core";
import Vue from "vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
//import { Props } from "@/types";
import { PropOptions } from "vue";
import { navigationComputed, eventComputed, themesComputed } from "@/state/helpers";

import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";

import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { Variable } from "@claimsgate/core-types";
const props: {
  padding: PropOptions<string>;
  condition: PropOptions<any>;
} = inputProps({
  excludeDefaults: true,

  extraProps: ["padding", "condition"],
});
export default Vue.extend({
  name: "CopyClaim",
  components: {},
  props: {
    ...props,
    targetWorkspaceId: {
      type: String,
      default: "",
    },
    targetFunnelId: {
      type: String,
      default: "",
    },
    targetPageId: {
      type: String,
      default: "",
    },

    isBuilder: {
      type: Boolean,
    },
  },
  computed: {
    ...authComputed,
    ...navigationComputed,
    ...eventComputed,
    ...themesComputed,
  },
  data() {
    return {
      workspaceService: new WorkspaceService(getFirebaseBackend().firebase()),
      userService: getUserHelper(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      userId: null,
      claimId: this.$route.params.claimId,
      funnelId: this.$route.params.funnelId,
      claimDataService: null as ClaimDataService,
      isLoading: false,
      funnelVariables: null as unknown as Array<Variable>,
    };
  },
  async mounted() {},
  methods: {
    async copyClaim() {
      try {
        console.log("[copyClaim] Running copyClaim!");
        this.userId = this.userService.getUserId();
        this.claimDataService = new ClaimDataService(this.userId, this.claimId);
        if (!this.claimDataService.getArtefact("copiedClaimId")) {
          const { data: result } = await onCallGateway<"copyClaimToWorkspace">({
            functionName: "copyClaimToWorkspace",
            data: {
              claimId: this.claimId,
              userId: this.userId,
              target: { workspaceId: this.targetWorkspaceId, funnelId: this.targetFunnelId, pageId: this.targetPageId },
            },
          });
          if ("success" in result) {
            if (result.success === true) {
              this.claimDataService.setArtefact("copiedClaimId", result.copiedClaimId);
              console.log("[copyClaim] Claim copied!", result.copiedClaimId);
            } else {
              console.error("[copyClaim] Error copying claim!", result);
            }
          } else {
            console.error("[copyClaim] Error copying claim!", result);
          }
        }
      } catch (error) {
        console.error("[copyClaim] Error", error);
      }
    },
  },
});
</script>
<template><div v-if="isBuilder">The copy claim block is invisible when rendered on the form.</div></template>
