<script lang="ts">
import Vue from "vue";
import TitleBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/TitleBlock.vue";
import ButtonBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/ButtonBlock.vue";
export default Vue.extend({
  name: "FAQ",
  props: {
    buttonExists: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TitleBlock,
    ButtonBlock,
  },
  data() {
    return {
      expandedQuestionId: null as number | null,
      questions: [
        {
          id: 1,
          question: "Am I eligible to file a car finance claim?",
          answer:
            "If you secured car finance through a hire purchase (HP) or personal contract purchase (PCP) agreement from 2007 onwards, you might qualify for a refund. <br><br> The FCA has yet to determine the full scope of its investigation, as DCAs were widely used before being prohibited. However, it is expected that only agreements signed after April 2007 will be reviewed, as this marks the FCA's start in regulating motor finance.",
        },
        {
          id: 2,
          question: "What is a commission on car finance?",
          answer:
            "When financing a car through PCP or HP, dealerships or brokers may have inflated your interest rate to increase their commission, often without your knowledge. This practice, known as Discretionary Commission Arrangement (DCA), was banned on January 28, 2021. <br> <br> If these commissions were not clearly disclosed, you could be owed compensation.",
        },
        {
          id: 3,
          question: "Which types of vehicle financing are eligible for making a claim?",
          answer:
            "Your car finance may qualify for a claim if: <br><br> • You purchased your vehicle through a personal contract purchase (PCP), which functions similarly to a loan for buying the car <br> • You had a hire purchase agreement, where you made monthly payments toward owning the vehicle",
        },
        {
          id: 4,
          question: "How do I sign up to make a car finance claim?",
          answer:
            "Signing up for a car finance claim doesn't need to be a headache. WithComplex Law, we aim to make the process as stress-free as possible – to register your claim with us, simply click on the button below to get started, and we'll do the rest.",
        },
        {
          id: 5,
          question: "Was my car finance affected by hidden commissions?",
          answer:
            "If you financed a vehicle between April 2007 and January 2021, your agreement may have included undisclosed commission payments. To determine if you're eligible for compensation, complete our quick eligibility check above and we'll assess your potential claim.",
        },
        {
          id: 6,
          question: "Is it possible to make claims for several finance agreements?",
          answer:
            "Absolutely. Each vehicle finance agreement represents a separate potential claim. If you've financed multiple vehicles since 2007 that may have had hidden commissions, you can pursue compensation for each individual agreement. Start the process by submitting your information above.",
        },
        {
          id: 7,
          question: "How long will the claim take?",
          answer:
            "The length of time it takes to finalise a claim can vary. However, we'll work hard to ensure you get the compensation you deserve and will keep you updated throughout the process. ",
        },
        {
          id: 8,
          question: "Why choose Complex Law?",
          answer:
            "Complex Law is committed to making car finance claims simple and stress-free. Our team works tirelessly to ensure you receive the compensation you deserve while guiding you through the entire process.",
        },
      ],
    };
  },
  methods: {
    toggleDropdown(id: number) {
      this.expandedQuestionId = this.expandedQuestionId === id ? null : id;
    },
    isExpanded(id: number) {
      return this.expandedQuestionId === id;
    },
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-bg-[#1B4753]">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <div class="tw-flex tw-pt-12">
        <TitleBlock title="Frequently Asked Questions" titleTextColor="tw-text-[#F9F9F9]" />
      </div>
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-16 tw-pt-0 tw-w-full tw-space-y-4">
        <b-col v-for="question in questions" :key="question.id" class="tw-w-full">
          <div
            class="faq-item tw-w-full tw-h-auto tw-rounded-lg tw-px-7 tw-py-4 tw-bg-transparent hover:tw-bg-[#E97969] tw-cursor-pointer tw-overflow-hidden tw-transition-all tw-duration-500"
            @click="toggleDropdown(question.id)"
          >
            <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
              <div class="tw-text-lg tw-font-semibold tw-text-[#f9f9f9] tw-lg:text-md tw-lg:font-semibold">
                {{ question.question }}
              </div>
              <i
                class="fa-solid fa-plus tw-text-[#f9f9f9] tw-transform tw-transition-transform tw-duration-500"
                :class="{ 'tw-rotate-45': isExpanded(question.id) }"
              ></i>
            </div>

            <div
              class="tw-transition-all tw-duration-500 tw-overflow-hidden"
              :class="{
                'tw-max-h-0 tw-opacity-0 tw-mt-0': !isExpanded(question.id),
                'tw-max-h-[500px] tw-opacity-100 tw-mt-2': isExpanded(question.id),
              }"
            >
              <p
                class="tw-text-lg tw-font-semibold tw-text-[#f9f9f9] tw-lg:text-md tw-lg:font-semibold tw-py-8"
                v-html="question.answer"
              ></p>
              <div v-if="buttonExists" class="tw-flex tw-justify-center">
                <ButtonBlock
                  text="Find My Agreements"
                  :onClick="emitRedirect"
                  :fullWidth="false"
                  :backgroundColor="'#1B4753'"
                  :textColor="'#FCFCFD'"
                  :hoverBackgroundColor="'#E97969'"
                  :hoverTextColor="'#FCFCFD'"
                  :borderColor="'#1B4753'"
                  :hoverBorderColor="'#E97969'"
                />
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faq-item {
  border: 3px solid #e97969 !important; /* Changed to white for better visibility */
}

.faq-item:hover {
  background-color: #e97969 !important;
}
</style>
