<script lang="ts">
import Vue from "vue";
import ButtonBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/ButtonBlock.vue";

export default Vue.extend({
  name: "HeroSection",
  components: {
    ButtonBlock,
  },
  data() {
    return {
      carCount: 0,
    };
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full" style="background-color: hsl(196, 51%, 12%); touch-action: manipulation">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-2 lg:tw-p-16">
      <div class="tw-flex tw-flex-col xl:tw-flex-row tw-items-center md:tw-gap-32 tw-gap-4 tw-mb-12 lg:tw-mb-0">
        <div class="tw-flex tw-flex-col tw-w-[358px] md:tw-w-[552px] tw-items-start tw-justify-center tw-gap-4">
          <div class="tw-flex tw-flex-col tw-mt-12 lg:tw-mt-0 tw-items-center tw-gap-6 tw-w-full">
            <span
              class="tw-font-sans tw-font-bold tw-text-[36px] tw-leading-[54px] tw-text-[#f9f9f9] tw-tracking-tighter tw-text-center tw-w-full lg:tw-text-left"
            >
              Check Your Mis-Sold PCP Claims
            </span>
            <!--
            <p class="tw-text-xl-regular tw-text-gray-800">
              Find out if you're eligible for a mis-sold car finance claim with our free agreement finder. It's quick
              and easy to check!
            </p>
            -->

            <span
              class="tw-text-[#f9f9f9] tw-inline-block tw-text-lg-regular tw-w-full tw-text-center lg:tw-text-left lg:tw-text-xl-regular"
              >How many vehicles have you financed?</span
            >

            <!-- how many cars financed counter -->
            <div class="tw-flex tw-items-center tw-gap-2 tw-mt-0 tw-w-full tw-touch-action-manipulation">
              <button
                @click="carCount = Math.max(0, carCount - 1)"
                class="tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#ffffff] tw-text-[#1B4753] tw-transition-all hover:tw-bg-[#68B4BC] tw-w-1/3 tw-border-0"
              >
                <span class="tw-text-3xl tw-font-semibold">-</span>
              </button>

              <div
                class="tw-w-1/3 tw-py-6 tw-px-10 tw-text-center tw-text-3xl tw-font-semibold tw-font-sans tw-bg-[#ffffff] tw-text-[#1B4753] tw-rounded-lg"
              >
                {{ carCount }}
              </div>

              <button
                @click="carCount = carCount + 1"
                class="tw-py-6 tw-px-10 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#ffffff] tw-text-[#1B4753] tw-transition-all hover:tw-bg-[#68B4BC] tw-w-1/3 tw-border-0"
              >
                <span class="tw-text-3xl tw-font-semibold">+</span>
              </button>
            </div>
          </div>

          <!-- Button Section -->
          <div class="tw-w-full">
            <!--
            <img
              src="https://cdn.claimsgate.co.uk/complexLawLandingPage/ArrowComplexLaw.png"
              loading="lazy"
              alt="Arrow"
              class="md:tw-block tw-absolute tw-w-8 md:tw-w-16 tw-h-auto tw-left-[340px] md:tw-left-[580.58px] tw-top-[-110px] md:tw-top-[-70px] tw-transform tw-rotate-[20deg] md:tw-rotate-0 md:tw-translate-y-[-50%]"
            />
            -->
            <ButtonBlock
              text="Start My Free Check"
              :onClick="emitRedirect"
              :backgroundColor="'#E97969'"
              :textColor="'#FFFFFF'"
              :hoverBackgroundColor="'#68B4BC'"
              :hoverTextColor="'#FFFFFF'"
              :borderColor="'#E97969'"
              :hoverBorderColor="'#68B4BC'"
            />
          </div>
          <!-- Logos and Footer Text -->
          <div class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full tw-mt-4">
            <p class="tw-text-lg-regular tw-text-[#f9f9f9] tw-text-center tw-w-full lg:tw-text-xl-regular">
              Claim up to
              <span class="tw-text-[#f9f9f9] tw-font-bold tw-underline tw-underline-offset-2">£1,000s</span>
            </p>
            <div class="tw-inline-flex tw-items-center tw-justify-center tw-gap-4">
              <img
                loading="lazy"
                src="https://cdn.claimsgate.co.uk/complexLawLandingPage/complexlogo.png"
                alt="Logo 1"
                class="tw-h-auto tw-w-[50px] tw-object-cover"
              />

              <img
                loading="lazy"
                src="https://cdn.claimsgate.co.uk/complexLawLandingPage/complextrustpilot.png"
                alt="Logo 1"
                class="tw-w-[156px] tw-h-auto tw-object-cover"
              />
            </div>
          </div>
        </div>

        <!-- Features Section -->
        <div class="tw-hidden tw-flex-col tw-w-full tw-mb-[16px]">
          <div
            class="tw-flex tw-flex-col xl:tw-flex-row tw-items-start xl:tw-items-center tw-justify-start xl:tw-justify-between tw-w-full tw-gap-6 xl:tw-gap-1"
          >
            <!-- Feature 1 -->
            <div class="tw-flex tw-items-center tw-gap-3">
              <font-awesome-icon icon="check-circle" class="tw-w-[20px] tw-h-[20px] tw-text-[#67b2bc]" />
              <span class="tw-text-md-semibold xl:tw-text-sm-semibold tw-leading-none tw-mt-[3px] tw-text-gray-200"
                >No Win No Fee</span
              >
            </div>

            <!-- Feature 2 -->
            <div class="tw-flex tw-items-center tw-gap-3">
              <font-awesome-icon icon="check-circle" class="tw-w-[20px] tw-h-[20px] tw-text-[#67b2bc]" />
              <span class="tw-text-md-semibold xl:tw-text-sm-semibold tw-leading-none tw-mt-[3px] tw-text-gray-200"
                >Secure Online Process</span
              >
            </div>

            <div class="tw-flex tw-items-center tw-gap-3">
              <font-awesome-icon icon="check-circle" class="tw-w-[20px] tw-h-[20px] tw-text-[#67b2bc]" />
              <span class="tw-text-md-semibold xl:tw-text-sm-semibold tw-leading-none tw-mt-[3px] tw-text-gray-200"
                >Join Over 100,000+ Drivers Helped</span
              >
            </div>
          </div>
        </div>

        <img
          src="https://cdn.claimsgate.co.uk/complexLawLandingPage/Untitled-42+Asset+2.png"
          loading="lazy"
          alt="Side Image"
          class="tw-w-auto tw-h-[260px] md:tw-w-auto md:tw-h-[400px] tw-object-contain"
        />
      </div>
    </div>
  </div>
</template>
