import { ComplexTypesService } from "@/helpers/ClaimsGate/ComplexTypesService";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserId } from "@/helpers/vue";

import Vue from "vue";

export function getData(state: Vue) {
  return {
    inputGroups: [[]],
    complexTypesService: new ComplexTypesService(),
    /** Used when the entire block passes or fails validation */
    isValid: null as boolean | null,
    inputAnswer: [{}],
    uiToggles: {
      isEntityValidating: false,
    },
    userDataService: null as UserDataService | null,
    userId: getUserId(),
  };
}
