import { getClaimDataService } from "@/helpers/vue";

import { BlockClaimsGateVehicleInstance } from "./BlockClaimsGateVehicle";
import { resetToSearchVehicleView, searchVehicle } from "./methods";

export async function mounted(state: BlockClaimsGateVehicleInstance) {
  state.userId = state.userService.getUserId();

  getClaimDataService<BlockClaimsGateVehicleInstance>(state);

  resetToSearchVehicleView(state);
  await parseQueryParameters(state);
}

async function parseQueryParameters(state: BlockClaimsGateVehicleInstance): Promise<void> {
  if (Object.keys(state.$route.query)?.length > 0 && state.$route.query?.vehicleRegistration) {
    state.vrm = state.$route.query?.vehicleRegistration?.toString();
    state.ownershipDate = state.$route.query?.ownershipDate?.toString();
  }

  if (state.vrm && state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    await searchVehicle(state);
  }

  if (state.vrm && !state.ownershipDate) {
    state.uiToggles.isSearchingByQueryParameters = true;
    state.ownershipDate = new Date().toISOString();
    await searchVehicle(state);
  }
}
