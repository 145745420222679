import { Variable, VariableType } from "@claimsgate/core-types";
import { DataArrayInstance } from "./DataArrayInstance";
import { defaultBlockProps } from "./defaultBlockProps";

function resetState(state: DataArrayInstance) {
  state.isValid = null;
  state.$emit("update:state", null);
}
export async function validations(state: DataArrayInstance): Promise<boolean> {
  resetState(state);
  if (!state.required && state.inputAnswer.length === 1 && Object.keys(state.inputAnswer[0]).length === 0) {
    setState(null, state);
    return true;
  }
  if (state.inputAnswer.length === 0) {
    setState(false, state);
    return false;
  }
  console.log("validating with ", state.inputAnswer);

  // answer = Array<Object> where each Object has a defined structure
  // The structure is like {firstName: string, lastName: string, email: string, dateOfBirth: string, bankName: string, propertyAddress: string, postcode: string}

  // Max's code is wrong because he does not care what the answer is
  // answer {firstName: "", lastName: ""}

  // Array<{field: "fistName", id: "ajdajd", type: string, required: false}>

  // Does thea answer have the field firstName?>
  // If yes, great
  // If not, problem

  // If the answer does not have firstName, then who cares.

  const result = state.complexTypesService.validate(state.inputAnswer, state.storeAs.structure);

  setState(result, state);
  console.log("validations", result);
  return result;
}
export async function computes(state: DataArrayInstance): Promise<any> {
  if (
    !state.required &&
    state.inputAnswer.length === 0 &&
    Object.values(state.inputAnswer[0]).every((value) => !value)
  ) {
    return [];
  }
  return state.inputAnswer;
}
function setState(isValid: boolean | null, state: DataArrayInstance): void {
  state.isValid = isValid;
  console.log("setting state data array", isValid);
  state.$emit("update:state", isValid);
}
export async function saveEntity(inputGroupIndex: number, state: DataArrayInstance): Promise<void> {
  if (!(await isValidEntity(inputGroupIndex, state))) {
    return;
  }

  // Otherwise close the modal
  state.$bvModal.hide(`inputGroupModal${inputGroupIndex}${state.entityName}`);
}
/**
 * Reset the state of all inputs of an entity and validate them, returning true if there is an error
 */
async function isValidEntity(index: number, state: DataArrayInstance): Promise<boolean> {
  let isValid = true;
  state.uiToggles.isEntityValidating = true;
  for (const input of state.inputGroups[index]) {
    let answer;
    if (input.blockType === "BlockSimpleEmail") {
      // run computes and grab answer
      if (!(await state.$refs[input.field][0].validations())) {
        state.uiToggles.isEntityValidating = false;
        return false;
      }

      state.inputAnswer[index][getFieldId(input.field, state)] = await state.$refs[input.field][0].computes();
      answer = state.inputAnswer[index][getFieldId(input.field, state)];
    } else {
      answer = state.inputAnswer[index][getFieldId(input.field, state)];
    }

    console.log("validateEntity", input.field, answer);

    if (answer === undefined || !answer || (Array.isArray(answer) && answer?.length === 0)) {
      input.props.invalidFeedback = "Please enter a value";
      input.props.state = false;
      isValid = false;
    } else {
      input.props.invalidFeedback = "";
      input.props.state = null;
    }

    if (input.field === "currentAddressLine1") {
      const currentAddressLine1 = state.inputAnswer[index][getFieldId("currentAddressLine1", state)] as string;

      if (currentAddressLine1?.length === 0) {
        input.props.invalidFeedback = "Please select an address";
        input.props.state = false;
        isValid = false;
      }

      if (currentAddressLine1?.length < 3) {
        input.props.invalidFeedback = "Please select a valid address";
        input.props.state = false;
        isValid = false;
      }
    }

    if (input.field === "currentCity") {
      const currentCity = state.inputAnswer[index][getFieldId("currentCity", state)] as string;

      if (currentCity?.length === 0) {
        input.props.invalidFeedback = "Please enter your city";
        input.props.state = false;

        isValid = false;
      }

      if (currentCity?.length < 3) {
        input.props.invalidFeedback = "Please enter a valid city";
        input.props.state = false;
        isValid = false;
      }
    }

    if (input.field === "currentPostcode") {
      const currentPostcode = state.inputAnswer[index][getFieldId("currentPostcode", state)] as string;

      if (currentPostcode?.length === 0) {
        input.props.invalidFeedback = "Please enter your postcode";
        input.props.state = false;
        isValid = false;
      }

      if (currentPostcode?.length < 6) {
        input.props.invalidFeedback = "Please enter a valid Irish Eircode, which is intended to be 7 characters long";

        input.props.state = false;
        isValid = false;
      }
    }
  }
  state.uiToggles.isEntityValidating = false;

  return isValid;
}
function resetEntityState(index: number, state: DataArrayInstance): void {
  for (const input of state.inputGroups[index]) {
    input.props.state = null;
  }
}

export function removeEntity(index: number, state: DataArrayInstance): void {
  resetState(state);
  state.inputAnswer.splice(index, 1);
  state.inputGroups.splice(index, 1);
}
export function generateInitialBlocks(state: DataArrayInstance) {
  const variable = state.storeAs;

  const blocks: Array<{ blockType: string; props: any; field: string; type: VariableType }> = [];

  variable.structure.forEach((dataPoint) => {
    if (dataPoint.blockType) {
      const blockType = dataPoint.blockType;

      const props = defaultBlockProps[dataPoint.field];

      blocks.push({ blockType, props, field: dataPoint.field, type: dataPoint.type });
    }
  });

  return blocks;
}
export function roundingClass(index: number, state: DataArrayInstance) {
  if (state.inputGroups?.length === 1) {
    return "";
  }
  if (index === 0) {
    return "rounded-top square-bottom";
  }
  if (index === state.inputGroups?.length - 1) {
    return "square-top rounded-bottom";
  }
  return "rounded-0";
}
export function editEntity(index: number, state: DataArrayInstance): void {
  resetEntityState(index, state);
  resetState(state);
  const editModalId = "inputGroupModal" + index + state.entityName;
  console.log("editEntity", editModalId);
  state.$bvModal.show(editModalId);
}
export async function addEntity(state: DataArrayInstance): Promise<void> {
  const intialBlocks = generateInitialBlocks(state);
  console.log("Initial Blocks", intialBlocks);

  state.inputGroups.push(intialBlocks);

  state.inputAnswer.push({});
  //state.$bvModal.show("inputGroupModal" + (state.inputGroups?.length - 1) + state.entityName);
  await state.$nextTick();
  resetState(state);
  editEntity(state.inputGroups.length - 1, state);
}
export function getFieldId(field: string, state: DataArrayInstance): string {
  console.log("getFieldId Structure", state.storeAs.structure);
  console.log("getFieldId", field, state.storeAs.structure);
  return state.storeAs.structure.find((element) => element.field === field)?.id;
}
export function getValidationClasses(index, state: DataArrayInstance): string {
  if (state.inputAnswer.length === 1) {
    return state.validationClasses;
  } else {
    if (state.isValid === true) {
      return "border-success border-thick";
    }
    if (state.isValid === false) {
      // check if this index is the invalid one
      console.log("getValidationClasses", index, state.inputAnswer[index]);
      if (
        Object.keys(state.inputAnswer[index]).length === state.storeAs.structure.length &&
        Object.values(state.inputAnswer[index]).every((value) => value !== undefined && value !== null && value !== "")
      ) {
        return "border-success border-thick";
      } else {
        return "border-danger border-thick";
      }
    }
    return "";
  }
}

export function deleteIncompleteEntityAnswer(index: number, state: DataArrayInstance): void {
  if (Object.keys(state.inputAnswer[index]).length !== state.storeAs.structure.length) {
    state.inputAnswer.splice(index, 1, {});
  }
}
