<script lang="ts">
import { Props } from "@/types";

import Vue from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

import BlockCountryPicker from "@/components/shared/blocks/countryPicker/countryPicker.vue";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId"],
});
console.log("🚀 ~ file: country.vue:12 ~ inputPropsValue:", inputPropsValue);

export default Vue.extend({
  name: "BlockCountry",
  components: { BlockCountryPicker },
  props: {
    ...inputPropsValue,
    answer: {
      type: String,
      required: false,
    },
    storeAs: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: false,
      newAnswer: "" as string,
      hover: false,
    };
  },
  watch: {
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    handleCountrySelected(country: { iso2: string }): void {
      console.log(country);

      if (country.iso2) {
        this.newAnswer = country.iso2;
        this.$emit("update:answer", this.newAnswer);
        this.$emit("update:state", null);
      }
    },
  },
  mounted() {
    if (this.answer) {
      this.newAnswer = this.answer;
    }
  },
  computed: {
    ...themesComputed,
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
  },
});
</script>

<style scoped>
button.btn.btn-grey {
  font-size: 1.015625rem;
  border-width: 2px;
  border-color: lightgrey;
  border-radius: 2px 0px 0px 2px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
input.form-control.prepended {
  border-radius: 0px 2px 2px 0px !important;
}
/* input.form-control {
  height: calc(1.5em + 2rem + 4px) !important;
  font-size: 1.015625rem;
} */
@media screen and (min-width: 1200px) {
  button.btn.btn-grey {
    padding-left: 2.85rem;
    padding-right: 2.85rem;
  }
}
@media screen and (min-width: 1600px) {
  button.btn.btn-grey {
    padding-left: 3.4rem;
    padding-right: 3.4rem;
  }
}
</style>

<template>
  <div>
    <BlockCountryPicker
      :info="info"
      :padding="padding"
      :label="label"
      :existingCountryCode="newAnswer"
      :invalidFeedback="invalidFeedback"
      :state="state"
      @input="handleCountrySelected"
    />
  </div>
</template>
