<script>
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { DateTime } from "@claimsgate/core/src/forwards/luxon/DateTime";
import { themesComputed } from "@/state/helpers";

/** @type { Props.BlockDatePicker } */
const inputPropsValue = inputProps({
  answerType: "date",
});
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  name: "BlockDatePicker",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
    /** @type {Props.Bool} */
    displayDays: {
      type: Boolean,
      required: false,
      default: true, // If false, deafults to: 00:01AM 1st of the Month
      allowVariable: false,
      description: "While true the date picker will allow day selection",
    },
    /** @type { Props.Num} */
    yearsStart: {
      type: Number,
      required: false,
      default: 1901,
      allowVariable: true,
      description: "The minimum year selectable on the date picker",
    },
    /** @type { Props.Num} */
    yearsEnd: {
      type: Number,
      required: false,
      default: 2100,
      allowVariable: true,
      description: "The maximum year selectable on the date picker",
    },
  },
  data() {
    return {
      /** @type {number} */
      day: null,
      /** @type {string} */
      month: null,
      /** @type {number} */
      year: null,
      err: "",
      monthsToDays: {
        January: 31,
        February: 29,
        March: 31,
        April: 30,
        May: 31,
        June: 30,
        July: 31,
        August: 31,
        September: 30,
        October: 31,
        November: 30,
        December: 31,
      },
      DAYS: [
        { value: null, text: "Day", disabled: true },
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      months: months,
      YEARS: undefined,
      newAnswer: this.answer,
    };
  },
  methods: {
    setDays(e) {
      if (this.day > this.monthsToDays[e]) {
        this.day = 1;
      }
      this.DAYS = [
        { value: null, text: "Day", disabled: true },
        ...Array.from({ length: this.monthsToDays[e] }, (_, i) => i + 1),
      ];
    },
    setFebruaryDays() {
      if (this.year && this.month === "February") {
        if (this.year % 4 === 0 && Object.keys(this.DAYS).length === 29) {
          this.DAYS.push(29);
          if (this.day > 29) {
            this.day = 1; // trigger vuelidate
          }
        } else if (this.year % 4 !== 0 && Object.keys(this.DAYS).length === 30) {
          this.DAYS.splice(-1);
          if (this.day > 28) {
            this.day = 1; // trigger vuelidate
          }
        } else {
          console.log(">>> setFebruaryDays is broken");
        }
      } else if (this.month === "February") {
        if (this.day > 29) {
          this.day = 1;
        }
      }
    },

    // combines day, month, and year into a single emitable string
    handleInput() {
      // Check if an answer has been provided for all of the required values
      // ? If displayDays is set to true, we require a day value to be given.
      if (!this.month || !this.year || (this.displayDays && !this.day)) {
        window.console.log("Date is bad :) !");
        this.$emit("update:value", "");
        this.$emit("update:answer", "");
        this.$emit("update:unparsedAnswer", "");
        this.$emit("update:state", null);
        return;
      }
      // create Date string
      let dateString = "";

      // year
      dateString = dateString.concat(this.year.toString());
      dateString = dateString.concat("-");

      // month
      const monthNo = this.getMonthNumberFromName(this.month);
      // Pad month with leading zero if needed
      const paddedMonth = monthNo.toString().padStart(2, "0");
      dateString = dateString.concat(paddedMonth);
      dateString = dateString.concat("-");

      // day
      if (this.displayDays) {
        // If displayDays is given then we add the day given to us by the user
        // Pad day with leading zero if needed
        const paddedDay = this.day.toString().padStart(2, "0");
        dateString = dateString.concat(paddedDay);
      } else {
        // If displayDays is not given, then we add the first day of the month
        dateString = dateString.concat("01");
      }

      // Emit both the ISO string and the unparsed YYYY-MM-DD format
      const isoString = DateTime.fromFormat(dateString, "yyyy-M-d").toISO({ includeOffset: false });

      this.$emit("update:value", isoString);
      this.$emit("update:answer", isoString);

      console.log("dateString", dateString);

      this.$emit("update:unparsedAnswer", dateString);
      this.$emit("update:state", null);

      return true;
    },

    /**
     * Returns -1 if the month can not be found in the months array
     * @param  {string} Month - name of the month to search=
     * @return {Number} Number indexing months from 1-12
     */
    getMonthNumberFromName(monthName) {
      return this.months.indexOf(monthName) + 1;
    },

    getMonthNameFromNumber(monthNumber) {
      return this.months[monthNumber - 1];
    },

    setDay(day) {
      this.day = parseInt(day);
    },
    setMonth(month) {
      this.month = month;
    },
    setYear(year) {
      this.year = parseInt(year);
    },
    parseAnswer() {
      // Guard against empty answers
      if (!this.answer || this.answer === "") return;

      let answer = this.answer;
      if (this.answer.toDate) {
        answer = this.answer.toDate();
      }

      try {
        var answerDate = new Date(Date.parse(answer));
        if (isNaN(answerDate.getTime())) return; // Guard against invalid dates

        // Now let us populate the day month and year fields based on the date
        this.year = answerDate.getFullYear();
        this.month = this.getMonthNameFromNumber(answerDate.getMonth() + 1);

        // Only set day if displayDays is true
        if (this.displayDays) {
          this.day = answerDate.getDate();
        } else {
          this.day = 1; // Set a default day when not displaying days
        }

        if (this.month) {
          this.setDays(this.month);
        }
      } catch (e) {
        console.warn("Error parsing date:", e);
      }
    },
  },
  computed: {
    ...themesComputed,
    monthsDropdown() {
      return [{ value: null, text: "Month", disabled: true }, ...months];
    },
    states() {
      const states = {
        day: null,
        month: null,
        year: null,
      };

      if (this.state === true) {
        states.day = true;
        states.month = true;
        states.year = true;
        return states;
      } else if (this.state === false) {
        states.day = false;
        states.month = false;
        states.year = false;
        return states;
      } else {
        return states;
      }
    },
  },
  watch: {
    yearsStart: {
      handler(newVal) {
        window.console.log("[years] change to ", newVal);
      },
    },

    state: {
      handler(state) {
        this.states.day = state;
        this.states.month = state;
        this.states.year = state;
      },
      immediate: true,
    },

    answer: {
      handler() {
        this.parseAnswer();
      },
    },
  },
  mounted() {
    if (typeof this.yearsStart === "string") {
      this.$emit("update:yearsStart", parseInt(this.yearsStart));
    }

    if (typeof this.yearsEnd === "string") {
      this.$emit("update:yearsEnd", parseInt(this.yearsEnd));
    }

    function range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    }
    var years = [];
    years.push({ value: null, text: "Year", disabled: true });

    window.console.log("here", this.yearsStart, this.yearsEnd, this.disabled);
    // @ts-ignore
    years = years.concat(range(parseInt(this.yearsStart), parseInt(this.yearsEnd))); // nearly did the c++ classic

    this.YEARS = years;
    // window.console.log("[years] is", years);
    console.log("CAll parse", this.answer);
    if (this.answer && this.answer !== "") {
      this.parseAnswer();
      // this.handleInput();
    }
  },
};
</script>
<style scoped>
.custom-select {
  /*color: #495057;*/

  border-width: 2px !important;
  /* border-color: #eff2f7 !important; */
  transition: 0.25s;
}
.custom-select:focus {
  border-width: 2px !important;
  border-color: var(--primary) !important;
  transition: 0.25s;
  box-shadow: none !important;
}
.disabled-background-color {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}
</style>
<template>
  <BlockInputWrapper
    :labelClass="labelClass"
    :infoClass="infoClass"
    :info="info"
    :padding="padding"
    :label="label"
    :invalidFeedback="invalidFeedback"
    :state="state"
  >
    <b-row class="justify-content-center no-gutters" :ref="'dropdowns'">
      <template v-if="displayDays">
        <b-col cols="3" class="pr-1 pb-0">
          <b-form-select
            :ref="'days'"
            days
            :size="theme.size"
            v-model="day"
            @change="setDay($event), handleInput()"
            :options="DAYS"
            :disabled="disabled"
            :state="states.day"
            :class="[disabled ? 'disabled-background-color' : componentClass]"
            data-cy="datepicker-day-select"
          ></b-form-select>
        </b-col>
      </template>
      <b-col cols="6" :class="{ displayDays: 'px-1' }">
        <b-form-select
          :ref="'months'"
          months
          :size="theme.size"
          v-model="month"
          @change="
            setMonth($event);
            setDays($event);
            setFebruaryDays();
            handleInput();
          "
          :options="monthsDropdown"
          :disabled="disabled"
          :state="states.month"
          :class="[disabled ? 'disabled-background-color' : '']"
          data-cy="datepicker-month-select"
        ></b-form-select>
      </b-col>
      <b-col class="pl-1">
        <b-form-select
          :ref="'years'"
          years
          :size="theme.size"
          v-model="year"
          @change="
            setYear($event);
            setFebruaryDays();
            handleInput();
            $emit('update:state', null);
          "
          :options="YEARS"
          :disabled="disabled"
          :state="states.year"
          :class="[disabled ? 'disabled-background-color' : '']"
          data-cy="datepicker-year-select"
        ></b-form-select>
      </b-col>
    </b-row>
  </BlockInputWrapper>
</template>
