<script lang="ts">
import Vue from "vue";

import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { BlockCompanyInstance, getData, getProps, mappedComputed, methods } from "./BlockCompany";

export default Vue.extend({
  name: "BlockCompany",
  components: { BlockInput, BlockDropdown, BlockPadding },
  computed: {
    ...mappedComputed(),
    fake(): string {
      return this.fake;
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    async searchCompany(): Promise<void> {
      await methods.searchCompany(this as BlockCompanyInstance);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this as BlockCompanyInstance);
    },
    async computes(): Promise<boolean> {
      return methods.computes(this as BlockCompanyInstance);
    },
    toggleManualEntry() {
      methods.toggleManualEntry(this as BlockCompanyInstance);
    },
    toggleCompanySearch() {
      methods.toggleCompanySearch(this as BlockCompanyInstance);
    },
  },
  props: {
    ...getProps(),
  },
  watch: {
    /** Propagate padding to the child components */
    padding: {
      handler() {
        Object.keys(this.BlockInputs).forEach((key) => {
          this.BlockInputs[key].padding = this.padding;
        });
      },
    },
    label: {
      handler() {
        this.BlockInputs.companyName.label = this.label as string;
      },
      immediate: true,
    },
  },
  async mounted() {
    await methods.mounted(this as BlockCompanyInstance);
  },
});
</script>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <template v-if="uiToggles.show.companySearch">
      <BlockInput v-bind.sync="BlockInputs.companyName">
        <template v-slot:form-input>
          <template v-if="!BlockInputs.searchCompanyButton.disabled">
            <b-input-group-append class="mt-0">
              <b-button
                @click="searchCompany"
                :variant="BlockInputs.searchCompanyButton.disabled ? 'gray' : 'primary'"
                class="text-white mb-0 mt-0"
                :size="BlockInputs.searchCompanyButton.size"
                :disabled="BlockInputs.searchCompanyButton.disabled"
                :class="{ 'btn-gray': BlockInputs.searchCompanyButton.disabled }"
                :data-cy="`company-search-button`"
              >
                <template v-if="uiToggles.loaders.isSearchingForCompany">
                  <b-spinner class="spinner-border-sm m-1" role="status"></b-spinner>
                </template>
                <template v-else> Search </template>
              </b-button>
            </b-input-group-append>
          </template>
        </template>
      </BlockInput>
    </template>

    <template v-if="uiToggles.show.companySelect">
      <BlockDropdown :data-cy="`company-select-dropdown`" v-bind.sync="BlockInputs.companySelect" padding="5" />
    </template>

    <template v-if="uiToggles.show.manualEntryOption">
      <a href="JavaScript:void(0)" class="font-weight-bold mt-2" @click="toggleManualEntry"
        >Can't find your company? Click here to enter it manually.</a
      >
    </template>

    <template v-if="uiToggles.show.manualEntry">
      <BlockInput v-bind.sync="BlockInputs.companyName" />
      <BlockInput
        v-bind.sync="BlockInputs.companyAddressLine1"
        info="Please enter the address of the company to the best of your knowledge."
      />

      <a href="JavaScript:void(0)" class="font-weight-bold mt-2" @click="toggleCompanySearch">
        Click here to return to the company search.
      </a>
    </template>
  </div>
</template>
