<template>
  <BlockInputWrapper :info="info" :padding="padding" :label="label" :invalidFeedback="invalidFeedback" :state="state">
    <div :class="['vue-tel-input', styleClasses, { disabled: disabled }]">
      <div
        v-click-outside="clickedOutside"
        aria-label="Country Picker"
        aria-haspopup="listbox"
        :aria-expanded="open"
        role="button"
        :class="[
          'vti__dropdown',
          { open: open, disabled: dropdownOptions.disabled },
          disabled ? 'disabled' : 'bg-grey-25',
        ]"
        :tabindex="dropdownOptions.tabindex"
        @keydown="keyboardNav"
        @click="toggleDropdown"
        @keydown.space="toggleDropdown"
        @keydown.esc="reset"
        @keydown.tab="reset"
      >
        <span class="vti__selection">
          <span
            v-if="dropdownOptions.showFlags && hasLoadedCountry"
            :class="['vti__flag', activeCountryCode.toLowerCase()]"
          ></span>
          <b-spinner v-else-if="!hasLoadedCountry" variant="secondary" class="align-middle mx-4" small></b-spinner>
          <span v-if="hasLoadedCountry" class="vti__dropdown-arrow ml-2">{{ open ? "▲" : "▼" }}</span>
          <span v-if="hasLoadedCountry" class="vti__country-code text-sm text-grey-900 font-weight-semibold ml-1">
            {{ activeCountry?.name }}
          </span>
        </span>
        <ul
          v-if="open"
          ref="list"
          class="vti__dropdown-list"
          :style="{ width: dropdownOptions.width }"
          :class="dropdownOpenDirection"
          role="listbox"
        >
          <input
            v-if="dropdownOptions.showSearchBox"
            class="vti__input vti__search_box"
            aria-label="Search by country name or country code"
            :placeholder="sortedCountries.length ? sortedCountries[0].name : ''"
            type="text"
            v-model="searchQuery"
            @click.stop
          />
          <li
            v-for="(pb, index) in sortedCountries"
            role="option"
            :class="['vti__dropdown-item', getItemClass(index, pb.iso2)]"
            :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
            tabindex="-1"
            @click="choose(pb)"
            @mousemove="selectedIndex = index"
            :aria-selected="activeCountryCode === pb.iso2 && !pb.preferred"
          >
            <div class="vti__flag-wrapper">
              <span v-if="dropdownOptions.showFlags" :class="['vti__flag', pb.iso2.toLowerCase(), 'align-middle']" />
            </div>

            <span class="text-sm text-grey-900 font-weight-semibold align-middle">{{ pb.name }}</span>
          </li>
        </ul>
      </div>

      <slot name="icon-right" />
    </div>
  </BlockInputWrapper>
</template>

<script>
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const blockPhoneInputProps = inputProps({ extraProps: ["placeholder"], placeholder: "Please enter an amount" });

import utils, { setCaretPosition } from "./utils";
import clickOutside from "./directives/click-outside";

function getDefault(key) {
  const value = utils.options[key];
  if (typeof value === "undefined") {
    return utils.options[key];
  }
  return value;
}

// let examples = null;
// const getExamples = () => new Promise(
//   (resolve) => (
//     examples
//       ? resolve(examples)
//       : import('libphonenumber-js/examples.mobile.json')
//         .then((results) => {
//           examples = results;
//           resolve(results);
//         })
//   ),
// );

export default {
  name: "BlockCountryPicker",
  directives: {
    clickOutside,
  },
  components: {
    BlockInputWrapper,
  },
  props: {
    existingCountryCode: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    allCountries: {
      type: Array,
      default: () => getDefault("allCountries"),
    },
    autoFormat: {
      type: Boolean,
      default: () => getDefault("autoFormat"),
    },
    customValidate: {
      type: [Boolean, RegExp],
      default: () => getDefault("customValidate"),
    },
    defaultCountry: {
      // Default country code, ie: 'AU'
      // Will override the current country of user
      type: [String, Number],
      default: () => getDefault("defaultCountry"),
    },
    disabled: {
      type: Boolean,
      default: () => getDefault("disabled"),
    },
    autoDefaultCountry: {
      type: Boolean,
      default: () => getDefault("autoDefaultCountry"),
    },
    dropdownOptions: {
      type: Object,
      default: () => getDefault("dropdownOptions"),
    },
    ignoredCountries: {
      type: Array,
      default: () => getDefault("ignoredCountries"),
    },
    inputOptions: {
      type: Object,
      default: () => getDefault("inputOptions"),
    },
    invalidMsg: {
      type: String,
      default: () => getDefault("invalidMsg"),
    },
    mode: {
      type: String,
      default: () => getDefault("mode"),
    },
    onlyCountries: {
      type: Array,
      default: () => getDefault("onlyCountries"),
    },
    preferredCountries: {
      type: Array,
      default: () => getDefault("preferredCountries"),
    },
    validCharactersOnly: {
      type: Boolean,
      default: () => getDefault("validCharactersOnly"),
    },
    styleClasses: {
      type: [String, Array, Object],
      default: () => getDefault("styleClasses"),
    },
    ...blockPhoneInputProps,
  },
  data() {
    return {
      amount: "",
      activeCountryCode: "",
      open: false,
      finishMounted: false,
      selectedIndex: null,
      typeToFindInput: "",
      typeToFindTimer: null,
      dropdownOpenDirection: "below",
      parsedPlaceholder: this.inputOptions.placeholder,
      searchQuery: "",
      hasLoadedCountry: false,
      funnelId: this.$route.params.funnelId,
      claimId: this.$route.params.claimId,
    };
  },
  computed: {
    activeCountry() {
      return this.findCountry(this.activeCountryCode);
    },
    filteredCountries() {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.allCountries.filter(({ iso2 }) => this.onlyCountries.some((c) => c.toUpperCase() === iso2));
      }

      if (this.ignoredCountries.length) {
        return this.allCountries.filter(
          ({ iso2 }) =>
            !this.ignoredCountries.includes(iso2.toUpperCase()) && !this.ignoredCountries.includes(iso2.toLowerCase())
        );
      }

      return this.allCountries;
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries).map((country) => ({
        ...country,
        preferred: true,
      }));

      // Create a local copy of this.filteredCountries and filter out the preferred countries
      const filteredCountries = this.filteredCountries.filter(
        (filteredCountry) => !preferredCountries.find((country) => filteredCountry.iso2 === country.iso2)
      );

      // Combine preferredCountries and filteredCountries to create countriesList
      const countriesList = [...preferredCountries, ...filteredCountries];
      if (!this.dropdownOptions.showSearchBox) {
        return countriesList;
      }
      return countriesList.filter(
        (c) => new RegExp(this.searchQuery, "i").test(c.name) || new RegExp(this.searchQuery, "i").test(c.iso2)
      );
    },
  },
  watch: {
    activeCountry(value, oldValue) {
      if (!value && oldValue?.iso2 && !this.inputOptions.showDialCode) {
        this.activeCountryCode = oldValue.iso2;
        return;
      }
      if (value?.iso2) {
        this.$emit("country-changed", value);
        // this.resetPlaceholder();
      }
    },
    "inputOptions.placeholder": function () {
      this.resetPlaceholder();
    },

    existingCountryCode: {
      immediate: true,
      handler(value) {
        if (value === this.activeCountryCode || !value) {
          return;
        }
        console.log("updating existingCountryCode", value);
        this.choose(value);
      },
    },

    open(isDropdownOpened) {
      // Emit open and close events
      if (isDropdownOpened) {
        this.setDropdownPosition();
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
  mounted() {
    this.initializeCountry()
      .then(() => {
        this.hasLoadedCountry = true;
      })
      .catch(console.error)
      .then(() => {
        this.finishMounted = true;
      });
  },
  methods: {
    async getCountry(claimId, funnelId) {
      const { data: result } = await onCallGateway({
        functionName: "getUserGeoLocation",

        data: { claimId, funnelId },
      });

      console.log("🚀 ~ file: utils.js:9 ~ getCountry ~ result:", result);

      if (!result) {
        throw new Error("unable to fetch the country");
      }

      if (result?.geo_data?.country_code) {
        return result.geo_data.country_code;
      }
    },
    resetPlaceholder() {
      this.parsedPlaceholder = this.inputOptions.placeholder;
      // TODO: Fix dynamicPlaceholder
      // if (!this.inputOptions.dynamicPlaceholder) {
      //   return result;
      // }
      // getExamples()
      //   .then((results) => {
      //     examples = results;
      //     const mode = (!this.mode || this.mode === 'auto') ? 'international' : this.mode;
      //     const number = getExampleNumber(this.activeCountryCode.toUpperCase(), results);
      //     this.parsedPlaceholder = number?.format(mode.toUpperCase()) || this.placeholder;
      //   })
      //   .catch(console.error);
    },
    async initializeCountry() {
      /**
       * 1. If the phone included prefix (i.e. +12), try to get the country and set it
       */
      if (this.existingCountryCode) {
        return;
      }

      const fallbackCountry = this.preferredCountries[0] || this.filteredCountries[0];
      /**
       * 3. Check if fetching country based on user's IP is allowed, set it as the default country
       */
      if (this.autoDefaultCountry) {
        try {
          const res = await this.getCountry(this.claimId, this.funnelId);

          if (this.existingCountryCode) {
            return;
          }
          this.choose(res || this.activeCountryCode);
          return;
        } catch (error) {
          if (error.message === "unable to fetch the country") {
            console.warn("unable to fetch the country");
          } else {
            console.error("non fatal error!", error);
          }
        }
      }
      if (this.defaultCountry) {
        if (typeof this.defaultCountry === "string") {
          if (this.existingCountryCode) {
            return;
          }
          this.choose(this.defaultCountry);
          return;
        }
        if (typeof this.defaultCountry === "number") {
          const country = this.findCountryByDialCode(this.defaultCountry);
          if (country) {
            if (this.existingCountryCode) {
              return;
            }
            this.choose(country.iso2);
            return;
          }
        }
      } else {
        /**
         * 4. Use the first country from preferred list (if available) or all countries list
         */
        if (this.existingCountryCode) {
          return;
        }
        this.choose(fallbackCountry);
        return;
      }
    },

    /**
     * Get the list of countries from the list of iso2 code
     */
    getCountries(list = []) {
      return list.map((countryCode) => this.findCountry(countryCode)).filter(Boolean);
    },
    findCountry(iso = "") {
      return this.filteredCountries.find((country) => country.iso2 === iso.toUpperCase());
    },
    findCountryByDialCode(dialCode) {
      return this.filteredCountries.find((country) => Number(country.dialCode) === dialCode);
    },
    getItemClass(index, iso2) {
      const highlighted = this.selectedIndex === index;
      const lastPreferred = index === this.preferredCountries.length - 1;
      const preferred = this.preferredCountries.some((c) => c.toUpperCase() === iso2);
      return {
        highlighted,
        "last-preferred": lastPreferred,
        preferred,
      };
    },
    choose(country) {
      let parsedCountry = country;
      if (typeof parsedCountry === "string") {
        parsedCountry = this.findCountry(parsedCountry);
      }

      if (!parsedCountry) {
        return;
      }

      // update value, even if international mode is NOT used
      this.activeCountryCode = parsedCountry.iso2 || "";
      this.onInput();
    },
    testCharacters() {
      if (this.validCharactersOnly) {
        const result = /^[()\-+0-9\s]*$/.test(this.amount);
        if (!result) {
          return false;
        }
      }
      if (this.customValidate) {
        return this.testCustomValidate();
      }
      return true;
    },
    testCustomValidate() {
      return this.customValidate instanceof RegExp ? this.customValidate.test(this.amount) : false;
    },
    onInput() {
      this.emitInput({ ...this.activeCountry, amount: this.amount });
    },
    emitInput(value) {
      this.$emit("input", value, this.$refs.input);
    },
    onBlur() {
      this.$emit("blur");
    },
    onFocus() {
      setCaretPosition(this.$refs.input, this.amount.length);
      this.$emit("focus");
    },
    onEnter() {
      this.$emit("enter");
    },
    onSpace() {
      this.$emit("space");
    },
    focus() {
      this.$refs.input.focus();
    },
    toggleDropdown(e) {
      if (this.disabled || this.dropdownOptions.disabled || e?.path?.[0]?.type === "text") {
        return;
      }
      this.searchQuery = "";
      this.open = !this.open;
    },
    clickedOutside() {
      this.open = false;
    },
    keyboardNav(e) {
      if (e.keyCode === 40) {
        // down arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(this.sortedCountries.length - 1, this.selectedIndex + 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        selEle.focus();
        if (selEle.offsetTop + selEle.clientHeight > this.$refs.list.scrollTop + this.$refs.list.clientHeight) {
          this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight + selEle.clientHeight;
        }
      } else if (e.keyCode === 38) {
        // up arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.sortedCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        selEle.focus();
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      } else if (e.keyCode === 13) {
        // enter key
        if (this.selectedIndex !== null) {
          this.choose(this.sortedCountries[this.selectedIndex]);
        }
        this.open = !this.open;
      } else {
        // typing a country's name
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = "";
        }, 700);
        // don't include preferred countries so we jump to the right place in the alphabet
        const typedCountryI = this.sortedCountries
          .slice(this.preferredCountries.length)
          .findIndex((c) => c.name.toLowerCase().startsWith(this.typeToFindInput));
        if (typedCountryI >= 0) {
          this.selectedIndex = this.preferredCountries.length + typedCountryI;
          const selEle = this.$refs.list.children[this.selectedIndex];
          const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop;
          const needToScrollBottom =
            selEle.offsetTop + selEle.clientHeight > this.$refs.list.scrollTop + this.$refs.list.clientHeight;
          if (needToScrollTop || needToScrollBottom) {
            this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      }
    },
    reset() {
      this.selectedIndex = this.sortedCountries.map((c) => c.iso2).indexOf(this.activeCountryCode);
      this.open = false;
    },
    setDropdownPosition() {
      const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;
      const hasEnoughSpaceBelow = spaceBelow > 200;
      if (hasEnoughSpaceBelow) {
        this.dropdownOpenDirection = "below";
      } else {
        this.dropdownOpenDirection = "above";
      }
    },
  },
};
</script>

<style scoped src="./assets/sprite.css"></style>
<style scoped src="./assets/component.css"></style>
