<script lang="ts">
import Vue from "vue";
import { PropType } from "vue";
import TitleBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/TitleBlock.vue";
import ButtonBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/ButtonBlock.vue";

export default Vue.extend({
  name: "HowItWorks",
  components: {
    TitleBlock,
    ButtonBlock,
  },
  props: {
    scrollToTop: {
      type: Function as PropType<() => void>,
      required: true,
    },
    buttonExists: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/RoundDocIcon.png",
          name: "Eligibility Check",
          description:
            "Before starting your claim, our system quickly assesses your eligibility based on financial agreements dating back to 2007. Find out in minutes if you qualify; no obligations, just clarity.",
        },
        {
          id: 2,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/RoundExploreIcon.png",
          name: "Claim Discovery",
          description:
            "Unlock financial recoveries with our compensation tracker, scanning past agreements to reveal hidden entitlements. Our free 2-minute assessment helps you uncover money that may be legally owed to you.",
        },
        {
          id: 3,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/RoundExpertIcon.png",
          name: "Expert Claim Management",
          description:
            "Our legal specialists take care of your claim from start to finish, handling the paperwork, negotiations, and legal processes to maximize your compensation so you don’t have to lift a finger.",
        },
        {
          id: 4,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/RoundFinishIcon.png",
          name: "The Finish Line",
          description:
            "Once your claim is approved, we negotiate the best possible settlement. When secured, funds are swiftly transferred to your bank, ensuring a smooth and hassle-free payout.",
        },
      ],
      IMAGES: {
        MONEYILLUSTRATION: "https://cdn.claimsgate.co.uk/complexLawLandingPage/MoneyIllustration.png",
      },
    };
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-w-full tw-py-8 tw-px-4 lg:tw-py-16 lg:tw-px-32 tw-font-sans"
    style="background-color: #fbfcfa"
  >
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1440px] tw-mx-auto lg:tw-px-32 tw-w-full tw-px-2">
      <TitleBlock
        title="How does it work?"
        description="Companies may overlook or intentionally withhold rightful compensation, but our service is designed to level the playing field. We provide a comprehensive claims investigation that empowers you to uncover and pursue money that is legally owed to you."
      />
      <div class="tw-flex tw-flex-col xl:tw-flex-row tw-flex-wrap tw-relative tw-w-full tw-justify-between tw-gap-6">
        <img
          :src="IMAGES.MONEYILLUSTRATION"
          loading="lazy"
          alt="money illustration"
          class="tw-z-1 tw-hidden xl:tw-block xl:tw-absolute tw-w-[410px] tw-h-[592px] tw-left-[387px] tw-top-[179px] tw-transform"
        />

        <div
          v-for="(section, index) in sections"
          :key="section.id"
          class="tw-z-2 tw-mb-6 lg:tw-mb-0 xl:tw-w-[369px] xl:tw-h-[441.33px] tw-w-full xl:tw-mr-6 tw-gap-6"
        >
          <div class="card h-100 mb-0 custom-card">
            <div class="card-body my-3 mx-1">
              <div class="d-flex flex-column align-items-center">
                <img :src="section.img" alt="Illustration" class="tw-w-[83.33px] tw-h-[83.33px]" />
                <div
                  class="d-flex flex-column align-items-center display-xs tw-font-sans font-weight-semibold my-4 w-100 text-center"
                  style="color: #101828"
                >
                  {{ section.name }}
                </div>
                <div class="w-100">
                  <p class="mb-2 tw-text-xl tw-font-weight-regular tw-text-center tw-font-sans" style="color: #1d2939">
                    {{ section.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Insert image after second item -->
          <div v-if="index === 1" class="tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-6 tw-mt-12">
            <img
              src="https://cdn.claimsgate.co.uk/complexLawLandingPage/MoneyIllustration.png"
              loading="lazy"
              alt="money illustration"
              class="tw-w-auto xl:tw-hidden tw-h-[350px] md:tw-w-auto md:tw-h-[500px] tw-object-contain"
            />
          </div>
        </div>
      </div>

      <div v-if="buttonExists" class="tw-flex tw-justify-center tw-my-12">
        <ButtonBlock
          text="Find My Agreements"
          :onClick="emitRedirect"
          :fullWidth="false"
          :backgroundColor="'#E5F083'"
          :textColor="'#1B4753'"
          :hoverBackgroundColor="'#68B4BC'"
          :hoverTextColor="'#FCFCFD'"
          :borderColor="'#E5F083'"
          :hoverBorderColor="'#68B4BC'"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #ffffff;
  border: 2px solid #e5f083;
  border-radius: 48px;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
