<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "ComplexQuestionModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    currentSectionIndex: {
      type: Number,
      required: true,
    },
    sections: {
      type: Number,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonText(): string {
      return this.isLastSection ? "Save" : "Next";
    },
    isLastSection(): boolean {
      return this.currentSectionIndex === this.sections - 1;
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("complexQuestionModal");
    },
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
    save() {
      this.$emit("save");
    },
  },
});
</script>
<template>
  <b-modal
    id="complexQuestionModal"
    class="modal-content"
    centered
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <template #modal-header>
      <div style="display: flex">
        <div>
          <h4 class="mb-1 text-grey-900 text-lg font-weight-semibold">
            {{ title }}
          </h4>

          <p class="mb-0 text-grey-600 text-xs">
            {{ subTitle }}
          </p>
        </div>
      </div>
      <div style="float: right">
        <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
          <i class="fas fa-times mx-1" />
        </b-button>
      </div>
    </template>

    <slot name="body"></slot>

    <template #modal-footer>
      <div class="d-flex w-100">
        <div class="grow w-100">
          <b-button
            size="md"
            variant="light"
            @click="previous()"
            class="w-100 mr-1"
            :disabled="currentSectionIndex === 0"
          >
            Previous
          </b-button>
        </div>

        <div class="grow w-100">
          <b-button size="md" variant="primary " @click="isLastSection ? save() : next()" class="w-100 ml-1">
            <template v-if="isProcessing">
              <b-spinner class="spinner-border-sm" role="status"></b-spinner>
            </template>
            <template v-else-if="buttonText === 'Save'">
              <b-icon icon="plus"></b-icon>
              {{ buttonText }}
            </template>
            <template v-else>
              {{ buttonText }}
            </template>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
