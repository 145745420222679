import { Props } from "@/types";
import { BlockCompanyProps } from "./BlockCompany";
import { BlockCompanyStore } from "./types";

export const blockCompanyStores: Array<{ name: BlockCompanyStore; type: string }> = [
  { name: "companyAddressLine1", type: "string" },
  { name: "companyName", type: "string" },
  { name: "companyNumber", type: "string" },
  { name: "companyPostcode", type: "string" },
  { name: "companyCity", type: "string" },
  { name: "companyClassification", type: "string" },
];
const blockCompanyProps: Record<keyof BlockCompanyProps, any> = {
  label: {
    type: String,
    default: "Company Name",
  } as Props.CGPropExtras<string>,
  padding: {
    type: String,
    required: false,
    default: "10",
    description: "The padding above the block",
  } as Props.CGPropExtras<string>,
  stores: {
    type: Array,
    required: false,
    default: (): Array<{ name: BlockCompanyStore; type: string }> => blockCompanyStores,
    description: "The variables stored by this block",
    allowVariable: false,
  } as Props.CGPropExtras<Array<{ name: BlockCompanyStore; type: string }>>,
  condition: {
    type: Object,
    required: false,
    default: (): Record<string, any> => ({
      type: "",
      actual: { id: "", field: "", type: "", group: "" },
      values: [],
      value: "",
    }),
    description: "The condition to show this block",
    allowVariable: false,
  } as Props.CGPropExtras<Record<string, any>>,
  isVisible: {
    type: Boolean,
    required: false,
    default: true,
    description: "Whether this block is visible",
    allowVariable: false,
  } as Props.CGPropExtras<boolean>,
};

export function getProps() {
  return {
    ...blockCompanyProps,
  };
}
