<script lang="ts">
import Vue from "vue";
import { PropOptions } from "vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { navigationComputed, eventComputed, themesComputed, formComputed } from "@/state/helpers";
import AmIEligible from "./components/AmIEligible.vue";
import HeroSection from "./components/HeroSection.vue";
import HowItWorks from "./components/HowItWorks.vue";
import FAQ from "./components/FAQ.vue";

const props: {
  padding: PropOptions<string>;
  condition: PropOptions<any>;
} = inputProps({
  excludeDefaults: true,
  extraProps: ["padding", "condition"],
});

export default Vue.extend({
  name: "ComplexLawPcpLandingPage",
  components: {
    AmIEligible,
    HeroSection,
    HowItWorks,
    FAQ,
  },
  props: {
    ...props,
    backgroundImageUrl: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    isBuilder: {
      type: Boolean,
    },
  },
  computed: {
    ...navigationComputed,
    ...eventComputed,
    ...themesComputed,
    ...formComputed,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    navigateToButton() {
      if (this.buttonLink) {
        this.$router.push(this.buttonLink);
      }
    },
    getQueryParams() {
      if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);
        const queryObject: Record<string, string> = {};

        params.forEach((value, key) => {
          queryObject[key] = value;
        });

        return queryObject;
      }
      return {};
    },

    handleRedirect() {
      console.log("handleRedirect");
      this.$store.dispatch("events/fire", { name: "next" });
    },
    scrollToTop() {
      // Scroll to the top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
});
</script>

<template>
  <div class="complex-law-pcp-landing-page">
    <div v-if="isBuilder" class="p-4 text-center">
      <p>Complex Law PCP Landing Page Block</p>
      <p class="text-muted small">This block will display a landing page for Complex Law PCP claims</p>
    </div>

    <div v-else class="tw-flex tw-flex-col tw-w-full">
      <!-- THIS EMIT REDIRECT FIRES WHEN THE CHILD TELLS US TO-->
      <HeroSection @emitRedirect="handleRedirect" />
      <AmIEligible @emitRedirect="handleRedirect" />
      <HowItWorks @emitRedirect="handleRedirect" />
      <FAQ @emitRedirect="handleRedirect" />
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");
</style>
