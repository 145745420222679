import { ComplexQuestionInstance } from "./ComplexQuestionInstance";
import { validateSection } from "./helpers";
import { setTitle } from "./helpers/setTitle";
import { resetFormState } from "./helpers/validateSection";

export function editEntity(index: number, state: ComplexQuestionInstance): void {
  state.currentEntityIndex = index;

  state.currentSectionIndex = 0;

  setTitle(state);

  resetFormState(state);

  state.$nextTick(() => {
    state.$bvModal.show("complexQuestionModal");
  });
}

export function removeEntity(index: number, state: ComplexQuestionInstance): void {
  state.answers.splice(index, 1);
}

export function addEntity(state: ComplexQuestionInstance): void {
  const answers = state.answers;

  state.$set(state.answers, answers.length, getDefaultAnswer(state));
}

export function getDefaultAnswer(state: ComplexQuestionInstance): Record<string, any> {
  const { form } = state;

  const answer: Record<string, any> = {};

  form.sections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.component === "BlockMultiSelect") {
        // MultiSelect is editing the default form defaultAnswer, couldn't figure out why...
        answer[question.field] = [];
      } else {
        answer[question.field] = question.defaultAnswer;
      }
    });
  });

  return answer;
}

export function reloadAnswers(
  existingAnswers: Array<Record<string, any>>,
  state: ComplexQuestionInstance
): Array<Record<string, any>> {
  const answers = existingAnswers.map((answer) => {
    const newAnswer = {};

    state.form.sections.forEach((section) => {
      section.questions.forEach((question) => {
        const existingAnswer = answer[question.field];
        if (existingAnswer) {
          newAnswer[question.field] = existingAnswer;
        } else {
          newAnswer[question.field] = question.defaultAnswer;
        }
      });
    });

    return newAnswer;
  });
  return answers;
}

export async function saveEntity(state: ComplexQuestionInstance): Promise<void> {
  try {
    state.uiToggles.isProcessing = true;
    state.uiToggles.isSaveError = false;
    state.$emit("update:state", null);

    if (!validateSection(state, state.currentEntityIndex, state.currentSectionIndex)) return;

    state.claimDataService.setArtefact(state.storeAs.id, state.answers);

    await state.claimDataService.update();
    state.$bvModal.hide("complexQuestionModal");
    state.uiToggles.isProcessing = false;
  } catch (error) {
    state.uiToggles.isProcessing = false;
    state.uiToggles.isSaveError = true;
  }
}

export async function nextSection(state: ComplexQuestionInstance): Promise<void> {
  if (!validateSection(state, state.currentEntityIndex, state.currentSectionIndex)) return;

  state.currentSectionIndex++;

  setTitle(state);
}

export async function validations(state: ComplexQuestionInstance): Promise<boolean> {
  const totalEntities = state.answers.length;
  state.$emit("update:state", null);

  const totalSections = state.form.sections.length;

  let allEntitiesValid = true;
  state.entityValidationStates = [];

  for (let e = 0; e < totalEntities; e++) {
    const entitySectionsValid = [];
    for (let s = 0; s < totalSections; s++) {
      if (!validateSection(state, e, s)) {
        allEntitiesValid = false;
        entitySectionsValid.push(false);
      } else {
        entitySectionsValid.push(true);
      }
    }
    state.entityValidationStates.push(entitySectionsValid.every((s) => s));
  }

  if (!allEntitiesValid) {
    state.invalidFeedback = "An error has occurred. Please check your entries and try again.";
    state.$emit("update:state", false);
    state.$emit("update:invalid-feedback", "An error has occurred. Please check your entries and try again.");
    return false;
  } else {
    state.$emit("update:state", false);
    return true;
  }
}

export async function computes(state: ComplexQuestionInstance): Promise<Array<any>> {
  return state.answers;
}
