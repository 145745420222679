import { ComplexQuestionInstance } from "./ComplexQuestionInstance";
export const computed = {
  sections,
  entityNames,
  conditionallyRenderQuestions,
};

export const mappedComputed = {};

function sections(state: ComplexQuestionInstance): number {
  return state.form.sections.length;
}

function entityNames(state: ComplexQuestionInstance) {
  /**
   * Example template: "Payment of {{paymentAmount.amount}} {{paymentAmount.currencyCode}} on {{paymentDate | dateToString}}
   */
  const entityNameTemplate = state.form.entityNameTemplate;

  const entityNames = state.answers?.map((answer) => {
    let replacedName = entityNameTemplate;

    const regex = /{{(.*?)}}/g;

    let match: RegExpExecArray;
    const matches = [];

    while ((match = regex.exec(replacedName))) {
      matches.push(match);
    }

    const replacements: [stringToReplace: string, value: any][] = matches.map((match) => {
      let key: string;
      let value: any;
      let formatter: string;

      const stringToReplace = match[0];
      const templateTag = match[1];

      if (templateTag.includes("|")) {
        key = templateTag?.split("|")[0]?.trim();
        formatter = templateTag?.split("|")[1]?.trim();
      } else {
        key = templateTag;
      }

      if (key.includes(".")) {
        const keys = key.split(".");
        while (keys.length) {
          const k = keys.shift();
          if (value) {
            value = value[k];
          } else {
            value = answer[k];
          }
        }
      } else {
        value = answer[key];
      }

      if (formatter) {
        value = formatters[formatter](value);
      }

      return [stringToReplace, value];
    });

    const hasValues = replacements.every((replacement) => !!replacement[1]);

    if (!hasValues) return state.form.entityName;

    for (const replacement of replacements) {
      replacedName = replacedName.replace(replacement[0], replacement[1]);
    }
    return replacedName;
  });

  return entityNames;
}

/**
 * Determine if the question should be rendered based on the condition
 */
function conditionallyRenderQuestions(state: ComplexQuestionInstance): Record<string, boolean> {
  const { answers, form, currentEntityIndex, currentSectionIndex } = state;
  const section = form.sections[currentSectionIndex];
  const questions = section.questions;
  const entity = answers[currentEntityIndex];

  return questions.reduce((acc, question) => {
    if (question.condition) {
      const condition = question.condition;
      acc[question.field] = entity[condition.field] === condition.value;
    } else {
      acc[question.field] = true;
    }
    return acc;
  }, {});
}

const formatters = {
  dateToString: (value: string) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  },
};
