<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockClaimsGateVehicleInstance } from "./BlockClaimsGateVehicle";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";

import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockClaimsGateVehicle",
  components: {
    Fragment,
    BlockButton,

    BlockSingleSelect,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockClaimsGateVehicleInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },
  },

  methods: {
    async submitDropout() {
      return methods.submitDropout(this as unknown as BlockClaimsGateVehicleInstance);
    },

    resetVehicleSearch() {
      methods.resetVehicleSearch(this as unknown as BlockClaimsGateVehicleInstance);
    },

    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockClaimsGateVehicleInstance);
    },

    displayDropout() {
      methods.displayDropout(this as unknown as BlockClaimsGateVehicleInstance);
    },

    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockClaimsGateVehicleInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockClaimsGateVehicleInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockClaimsGateVehicleInstance);
    },

    async submitVehicleConfirmation(): Promise<void> {
      await methods.submitVehicleConfirmation(this as unknown as BlockClaimsGateVehicleInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockClaimsGateVehicleInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockClaimsGateVehicleInstance);
  },
});
</script>

<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.selected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #3452e1 !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #eff2f7 !important;
  transition: 0.25s;
}

.input-group > .input-group-prepend {
  flex: 0 0 15%;
}
.input-group .input-group-text {
  width: 100%;
}
/* Reg Input styling */
.reg-input-selected {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}
.input-group-prepend:focus {
  border-color: var(--primary) !important;
}
.btn-glow,
.btn-glow:active,
.btn-glow:focus,
.btn-glow:hover {
  box-shadow: 0px 0px 30px -10px #3452e1 !important;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder {
  /* Recent browsers */
  text-transform: none;
}
.disabled {
  background-color: #e9ecef !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}

.form-control {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.prepend-border {
  border: 2px solid var(--primary) !important;
}
</style>
<template>
  <Fragment>
    <BlockPadding :padding="padding" />

    <template v-if="uiToggles.isSearchingByQueryParameters">
      <div class="d-flex justify-content-center align-items-center flex-column" style="padding-top: 50px">
        <div>
          <b-icon class="fa fa-smog" animation="cylon" font-scale="3.75" :style="[{ color: theme.primaryColour }]" />
          <b-icon class="fa fa-car-side" animation="cylon" font-scale="4" :style="[{ color: theme.primaryColour }]" />
        </div>
        <h3 :style="[theme.textColourStyles]" class="mt-2">
          <span :style="[{ color: theme.primaryColour }]">Searching for your vehicle</span>
        </h3>
      </div>
    </template>

    <!-- Initial search -->
    <template v-if="uiToggles.isVehicleSearchVisible && !uiToggles.isSearchingByQueryParameters">
      <h5 class="mb-0 font-weight-semibold text-grey-900">What is your Vehicle Registration Number?</h5>
      <p class="mb-1 text-grey-700">Please enter your vehicle registration or the vehicle identification number</p>

      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text class="justify-content-center reg-input-selected">
            <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          class="border reg-input-selected"
          size="lg"
          maxLength="20"
          v-model="vrm"
          :state="vrmInputState"
          placeholder="Example: AB12 CDE"
          @update="vrmInputState = null"
        ></b-form-input>
      </b-input-group>

      <template v-if="!vrmInputState">
        <b-form-invalid-feedback :state="vrmInputState" id="input-live-feedback">
          {{ vrmInputInvalidFeedback }}
        </b-form-invalid-feedback>
      </template>

      <template v-if="pageId === 'KquOL6noYjuJGz1AOX3L'">
        <h5 class="text-primary-500 mt-1 text-sm pt-2" role="button">
          If this claim has been raised in error, or if it is a duplicate, please click the button below to cancel this
          claim.
        </h5>
        <b-button variant="secondary" @click="displayDropout" clas="text-white" size="md"> Cancel this claim </b-button>
      </template>

      <b-modal id="helpModal" title="Find Your Vehicle" size="md" centered hide-footer>
        <template #modal-header="{ close }">
          <div style="display: block">
            <h4>Find Your Vehicle</h4>
          </div>

          <div style="float: right">
            <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
              <i class="fas fa-times mx-1" />
            </b-button>
          </div>
        </template>

        <h5>Current Registrations</h5>
        <p>
          Make sure to search for the Registration Number that is on the vehicle today. If you are not the current
          owner, note that the current owner may have changed the Registration Number.
        </p>

        <h5>Current Mistakes</h5>
        <p>
          If you vehicle uses a standard registration plate, it should be in the format LLNN LLL. L - letter, N -
          number. Common mistakes can be swapping 0 for O, or I for 1.
        </p>
      </b-modal>

      <BlockButton
        @clicked="searchVehicle"
        v-bind="BlockInputs.searchRegButton"
        v-bind.sync="BlockInputs.searchRegButton"
      />
    </template>

    <template v-if="uiToggles.showVehicleConfirmation">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <h4 class="text-grey-700 font-weight-semibold">Vehicle Found</h4>
          <h4 class="text-grey-900">
            We found your vehicle, <b>{{ vehicle?.make }}</b
            >, <b>{{ vehicle?.model }}</b
            >, <b>{{ vehicle?.yearOfManufacture }}</b
            >, <b>{{ vehicle?.colour }}</b
            >, <b>{{ vehicle?.fuelType }}</b>
          </h4>

          <h5 class="text-primary-700 font-weight-semibold" role="button" @click="notMyVehicle">
            <span>Change your vehicle? Click here</span>
          </h5>
        </b-card-body>
      </b-card>

      <BlockPadding :padding="padding" />
      <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Confirmation</h5>
      <p class="mb-1 text-grey-700">Please confirm the vehicle details shown above are correct</p>
      <BlockSingleSelect
        v-bind="BlockInputs.vehicleConfirmationSingleSelect"
        v-bind.sync="BlockInputs.vehicleConfirmationSingleSelect"
        padding="0"
      />
      <BlockButton
        @clicked="submitVehicleConfirmation"
        v-bind="BlockInputs.submitVehicleConfirmationButton"
        v-bind.sync="BlockInputs.submitVehicleConfirmationButton"
      />
    </template>

    <template v-if="uiToggles.isKeeperSelectVisible">
      <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
        <b-card-body style="padding-bottom: 0.25rem !important">
          <h4 class="text-grey-700 font-weight-semibold">Vehicle Found</h4>
          <h4 class="text-grey-900">
            We found your vehicle, <b>{{ vehicle?.make }}</b
            >, <b>{{ vehicle?.model }}</b
            >, <b>{{ vehicle?.yearOfManufacture }}</b
            >, <b>{{ vehicle?.colour }}</b
            >, <b>{{ vehicle?.fuelType }}</b>
          </h4>
          <h5 class="text-primary-700 font-weight-semibold" role="button" @click="notMyVehicle">
            <span>Change your vehicle? Click here</span>
          </h5>
        </b-card-body>
      </b-card>

      <BlockPadding :padding="padding" />

      <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
      <p class="mb-1 text-grey-700">Please select the date when you purchased the vehicle</p>

      <BlockSingleSelect
        v-bind="BlockInputs.keeperSelectSingleSelect"
        v-bind.sync="BlockInputs.keeperSelectSingleSelect"
        padding="0"
      />
      <BlockButton
        @clicked="submitKeeperSelect"
        v-bind="BlockInputs.submitKeeperSelectButton"
        v-bind.sync="BlockInputs.submitKeeperSelectButton"
      />
    </template>

    <!-- If the claimant wants to dropout of the claim -->
    <template v-if="uiToggles.isDropoutVisible">
      <BlockSingleSelect v-bind.sync="BlockInputs.dropoutSingleSelect" padding="0" />

      <h5 class="text-primary-500 mt-1 text-sm pt-2">
        If you would like to search for a vehicle, click the button below.
      </h5>
      <b-button variant="secondary" @click="resetVehicleSearch" clas="text-white" size="md">
        Search for a vehicle
      </b-button>

      <BlockButton @clicked="submitDropout" v-bind.sync="BlockInputs.submitDropoutButton" />
    </template>
  </Fragment>
</template>
