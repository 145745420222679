<script lang="ts">
import { Props } from "@/types";

import Vue, { PropOptions, PropType } from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

import { Variable } from "@claimsgate/core-types";
import BlockCurrencyInput from "@/components/shared/blocks/currencyInput/currencyInput.vue";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId"],
});

export default Vue.extend({
  name: "BlockCurrency",
  components: { BlockCurrencyInput },
  props: {
    ...inputPropsValue,
    storeAs: {
      type: [Object, String],
    } as PropOptions<Variable>,
    answer: {
      type: [Object, String] as PropType<{ currencyCode: string; currencyCountryCode: string; amount: number }>,
      required: false,
    },
    minValue: {
      type: String as PropType<string>,
      required: false,
      description: "The minimum value for the currency input",
      default: "0",
    } as Props.CGPropExtras<string>,
    maxValue: {
      type: String as PropType<string>,
      required: false,
      description: "The maximum value for the currency input",
      default: "1000000000",
    } as Props.CGPropExtras<string>,
  },
  data() {
    return {
      selected: false,
      newAnswer: { currencyCode: "", amount: 0, currencyCountryCode: "" } as {
        currencyCountryCode: string;
        currencyCode: string;
        amount: number;
      },
      hover: false,
    };
  },
  watch: {
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    // Array where the first element is {currencyCode: string, value: string} and the second element is the value

    handleCurrencySelected(currency: { currencyCode: string; amount: string; iso2: string }): void {
      console.log(currency);

      if (currency.amount === "") {
        // This is so conditional processor knows the value is empty
        this.newAnswer = null as any;
        this.$emit("update:answer", null);
        return;
      }
      if (currency.currencyCode && currency.amount) {
        this.newAnswer = {
          currencyCode: currency.currencyCode,
          currencyCountryCode: currency.iso2,
          amount: parseFloat(currency.amount),
        };
        this.$emit("update:answer", this.newAnswer);
        this.$emit("update:state", null);
      }
    },

    validations(): boolean {
      if (!this.required) {
        this.$emit("update:state", true);
        return true;
      }

      if (!this.newAnswer?.amount) {
        this.$emit("update:state", false);
        this.$emit("update:invalidFeedback", this.$t("blocks.currency.enterValue"));
        return false;
      }
      if (this.newAnswer.amount < parseInt(this.minValue) || this.newAnswer.amount > parseInt(this.maxValue)) {
        this.$emit("update:state", false);
        this.$emit(
          "update:invalidFeedback",
          `Please enter a value between ${Number(this.minValue).toLocaleString()} and ${Number(
            this.maxValue
          ).toLocaleString()} ${this.newAnswer.currencyCode}.`
        );

        return false;
      }
      this.$emit("update:state", true);
      return true;
    },

    computes(): { currencyCode: string; currencyCountryCode: string; amount: number } {
      return {
        currencyCode: this.newAnswer.currencyCode,
        currencyCountryCode: this.newAnswer.currencyCountryCode,
        amount: this.newAnswer.amount,
      };
    },
  },
  mounted() {
    if (this.answer) {
      this.newAnswer = {
        currencyCode: this.answer.currencyCode,
        currencyCountryCode: this.answer.currencyCountryCode,
        amount: this.answer.amount,
      };
    }
  },
  computed: {
    ...themesComputed,
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
  },
});
</script>

<style scoped>
button.btn.btn-grey {
  font-size: 1.015625rem;
  border-width: 2px;
  border-color: lightgrey;
  border-radius: 2px 0px 0px 2px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
input.form-control.prepended {
  border-radius: 0px 2px 2px 0px !important;
}
/* input.form-control {
  height: calc(1.5em + 2rem + 4px) !important;
  font-size: 1.015625rem;
} */
@media screen and (min-width: 1200px) {
  button.btn.btn-grey {
    padding-left: 2.85rem;
    padding-right: 2.85rem;
  }
}
@media screen and (min-width: 1600px) {
  button.btn.btn-grey {
    padding-left: 3.4rem;
    padding-right: 3.4rem;
  }
}
</style>

<template>
  <div>
    <BlockCurrencyInput
      :info="info"
      :padding="padding"
      :label="label"
      :existingCurrencyValue="newAnswer.amount"
      :existingCurrencyCountryCode="newAnswer.currencyCountryCode"
      :invalidFeedback="invalidFeedback"
      :state="state"
      :validCharactersOnly="true"
      :preferredCountries="[
        'GB',
        'US',
        'CA',
        'AU',
        'DE',
        'FR',
        'IT',
        'ES',
        'NL',
        'BR',
        'MX',
        'ZA',
        'SE',
        'NO',
        'DK',
        'BE',
        'CH',
        'IE',
        'NZ',
      ]"
      @input="handleCurrencySelected"
    />
  </div>
</template>
