<script lang="ts">
import Vue from "vue";
import FileIcon from "@/components/shared/styleWrappers/fileIcon/fileIcon.vue";

// Types
import { Props } from "@/types";

// Blocks
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { getFirebaseBackend } from "@/authUtils";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { FileMeta } from "@claimsgate/core-types";

export default Vue.extend({
  name: "BlockDisplayFile",
  components: {
    BlockPadding,
    FileIcon,
  },
  props: {
    padding: {
      type: String,
      description: "The padding above the block",
      default: "10",
    } as Props.CGPropExtras<string>,
    answer: {
      required: false,
      allowVariable: false,
      description: "The file object",
    } as Props.CGPropExtras<any>,
  },
  data() {
    return {
      userService: getUserHelper(),
      files: [] as FileMeta[],
    };
  },
  computed: {},

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString.toDate());
      return date.toLocaleString();
    },

    uploadedBy(file: FileMeta) {
      const name = file.uploadedByName ?? "Client";
      const action = (file.fileVersion ?? 1) > 1 ? "Edited" : "Uploaded";
      return `${action} by ${name}`;
    },

    bytesToFileSize(bytes: number) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) {
        return "0 Byte";
      }
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
    },
    async getStorageFileSize(storagePath: string) {
      const fileMetadata = await getFirebaseBackend().firebaseStorage().ref(storagePath).getMetadata();
      return fileMetadata.size;
    },
    viewFile(fileId: string) {
      window.open(`${window.location.origin}/files/${fileId}`, "_blank");
    },
  },
  async mounted() {
    const userId = this.userService.getUserId();
    this.files = await Promise.all(
      this.answer.map(async (file) => {
        const fileMeta = (
          await getFirebaseBackend().firestore().collection("users").doc(userId).collection("files").doc(file.id).get()
        ).data() as FileMeta;
        fileMeta.fileSize = await this.getStorageFileSize(fileMeta.path);
        if (!fileMeta || !fileMeta.path) {
          return null;
        }
        return fileMeta;
      })
    );
  },
  watch: {},
});
</script>
<style scoped>
.underlineOnHover:hover {
  text-decoration: underline;
}
</style>
<template>
  <div>
    <BlockPadding :padding="padding" />
    <b-card
      class="mx-0 px-0 shadow-none border border-grey-100 mt-3 mb-3 bg-hover-grey-200"
      v-for="(file, index) in files"
      :key="'fileDisplayCard' + file.id + index"
      @click="viewFile(file.id)"
    >
      <b-row no-gutters>
        <b-col cols="9">
          <div class="d-flex flex-row">
            <div>
              <FileIcon :fileExtension="file.extension" />
            </div>

            <div class="d-flex flex-column ml-2 justify-content-center">
              <span class="text-sm text-grey-700 font-weight-semibold d-inline-block mb-0">{{ file.name }}</span>
              <span class="d-inline-block mt-0 text-grey-500 text-sm">
                Uploaded at {{ formatDate(file.createdAt) }}, {{ "Version " + (file.fileVersion ?? 1).toString() }},
                {{ "Size " + bytesToFileSize(file.fileSize) }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
