import { Form } from "./ComplexQuestionTypes";

export const form: Form = {
  entityNameTemplate:
    "Payment of {{paymentAmount.amount}} {{paymentAmount.currencyCode}} on {{paymentDate | dateToString}}",
  entityName: "Payment",
  sections: [
    {
      title: "OneCoin Payment Information",
      subtitle: "Complete the following form for a single payment.",
      questions: [
        // 3A
        {
          field: "paymentDate",
          required: true,
          props: {
            label: "On which date did you make the payment?",
            info: "Provide an approximate date if necessary",
            state: null,
            invalidFeedback: "",
            yearsStart: 2014,
            yearsEnd: 2024,
          },
          type: "string",
          component: "BlockDatepicker",
          defaultAnswer: "",
        },
        // 3B
        {
          field: "paymentAmount",
          required: true,
          props: {
            label: "How much did you pay?",
            info: "Provide an approximate amount if necessary",
            state: null,
            invalidFeedback: "",
          },
          type: "object",
          component: "BlockCurrency",
          defaultAnswer: { currencyCode: "", amount: 0, currencyCountryCode: "" },
        },
      ],
    },
    {
      questions: [
        // 3C
        {
          field: "paymentCountry",
          required: true,
          props: {
            label: "What country did you live in when the payment was made?",
            info: "",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockCountry",
          defaultAnswer: "",
        },
        // 3D
        {
          field: "usedForeignAccount",
          required: true,
          props: {
            label: "Did you invest using a foreign bank account or credit card?",
            info: "i.e. from a country other than the country where you lived",
            options: ["Yes", "No", "Don't know"],
            other: false,
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockSingleSelect",
          defaultAnswer: "",
        },
        // 3D-i
        {
          field: "foreignAccountCountry",
          required: true,
          props: {
            label: "Which country did you invest from?",
            info: "",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockCountry",
          defaultAnswer: "",
          condition: {
            value: "Yes",
            field: "usedForeignAccount",
          },
        },
      ],
    },
    {
      questions: [
        // 3E
        {
          props: {
            label: "Did you make this investment on behalf of yourself or someone else? ",
            info: "",
            inputType: "singleSelect",
            state: null,
            invalidFeedback: "",
            options: ["Myself", "Someone else"],
            other: true,
          },
          required: true,
          field: "investmentOnBehalfOf",
          type: "string",
          component: "BlockSingleSelect",
          defaultAnswer: "",
        },
        // 3Fi
        // {
        //   props: {
        //     label: "Who was the investment for?",
        //     info: "",
        //     inputType: "text",
        //     state: null,
        //     invalidFeedback: "",
        //   },
        //   required: true,
        //   field: "investmentFor",
        //   type: "string",
        //   component: "BlockInput",
        //   defaultAnswer: "",
        //   condition: {
        //     value: "Someone else",
        //     field: "investmentOnBehalfOf",
        //   },
        // },
        // 3F
        {
          props: {
            label:
              "Unless you tell us otherwise, we will assume that you invested using your own money. If you invested using somebody else's money, please provide further details here.",
            info: "",
            inputType: "text",
            state: null,
            invalidFeedback: "",
          },
          field: "fundsSource",
          type: "string",
          component: "BlockInput",
          defaultAnswer: "",
          required: false,
          // condition: {
          //   value: "Someone else",
          //   field: "investmentOnBehalfOf",
          // },
        },
        // 3G
      ],
    },
    {
      title: "Bank Account Details",
      subtitle: "If available, please provide the details of the bank account that you sent the funds to (optional)",
      questions: [
        // 3G
        {
          props: {
            label: "Bank Name",
            info: "",
            inputType: "text",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockInput",
          defaultAnswer: "",
          field: "bankName",
        },
        {
          props: {
            label: "Account Name",
            info: "",
            inputType: "text",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockInput",
          defaultAnswer: "",
          field: "accountName",
        },
        {
          props: {
            label: "Account Number",
            info: "",
            inputType: "text",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockInput",
          defaultAnswer: "",
          field: "accountNumber",
        },
        {
          props: {
            label: "IBAN, BIC or SWIFT Code",
            info: "",
            inputType: "text",
            state: null,
            invalidFeedback: "",
          },
          type: "string",
          component: "BlockInput",
          defaultAnswer: "",
          field: "internationalCode",
        },
        {
          props: {
            label: "Other Information",
            info: "",
            rows: 3,
            state: null,
            invalidFeedback: "",
          },

          type: "string",
          component: "BlockTextArea",
          defaultAnswer: "",
          field: "otherInformation",
        },
      ],
    },

    {
      questions: [
        // 3I
        {
          props: {
            label: "Which OneCoin package(s) did you receive in return for your payment?",
            info: "",
            inputType: "text",
            options: [
              "Starter pack",
              "Trader pack",
              "Pro trader pack",
              "Executive trader pack",
              "Tycoon trader pack",
              "Tycoon Plus trader pack",
              "Premium Trader pack",
              "Infinity trade pack",
              "I am not sure",
            ],
            state: null,
            invalidFeedback: "",
            other: true,
          },
          field: "oneCoinPackage",
          type: "array",
          component: "BlockMultiSelect",
          defaultAnswer: [],
        },
      ],
    },
  ],
};
