import Vue from "vue";
import { methods } from "./methods";
import { getProps } from "./props";
import { getData } from "./data";
import { computed, mappedComputed } from "./computed";
import { BlockClaimAddressStore } from "./types";
export { methods, getProps, getData, computed, mappedComputed };

// Defined this here as wanted to use local type
export interface BlockClaimAddressProps {
  padding: string;
  prefix: string;
  stores: Array<Record<BlockClaimAddressStore, string>>;
  label: string;
  info: string;
  required: boolean;
  condition: any;
  forceInternationalAddress: boolean;
  restrictCountry: string;
}

export type Methods = keyof typeof methods;
export type Data = ReturnType<typeof getData>;
export type Computed = { [K in keyof typeof computed]: ReturnType<(typeof computed)[K]> } & ReturnType<
  typeof mappedComputed
>;

export type BlockClaimAddressInstance = Vue & Data & Methods & Computed & BlockClaimAddressProps;
