import { Props } from "@/types";
import { ClaimsGateVehicleStore } from "./types";

export function getProps() {
  return {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,

    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },

    invalidFeedback: {
      type: String,
      required: false,
      allowVariable: false,
    } as Props.CGPropExtras<string>,
    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: ClaimsGateVehicleStore; type: string }> => [
        { name: "model", type: "string" },
        { name: "make", type: "string" },

        { name: "keeperChangeHistory", type: "array" },
        { name: "keeperStartDate", type: "date" },
        { name: "keeperEndDate", type: "date" },
        { name: "isCurrentKeeper", type: "boolean" },
        { name: "dateFirstRegistered", type: "date" },
        { name: "engineModelCode", type: "array" },

        // { name: "engineFamily", type: "array" },
        { name: "fuelType", type: "string" },
        { name: "vehicleRegistration", type: "string" },
        { name: "vin", type: "string" },
        { name: "engineNumber", type: "string" },

        // { name: "keeperChangeCount", type: "number" },

        { name: "yearOfManufacture", type: "number" },
        { name: "vinLast5", type: "number" },
        { name: "series", type: "string" },
        { name: "numberOfCylinders", type: "number" },
        { name: "engineCapacity", type: "number" },

        { name: "maximumPowerInKw", type: "string" },

        { name: "colour", type: "string" },

        { name: "vehicleDataSource", type: "string" },
        { name: "numberOfPreviousKeepers", type: "number" },
        { name: "dropoutAnswer", type: "string" },

        { name: "cgfDescriptionShort", type: "string" },
        { name: "cgfDescriptionLong", type: "string" },
        { name: "cgfDescriptionSource", type: "string" },
        { name: "cgfVrmCurrStyle", type: "string" },
        { name: "cgfPrivateRegistration", type: "boolean" },
        { name: "cgfKeeper25May2018", type: "string" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: ClaimsGateVehicleStore; type: string }>>,
  };
}
