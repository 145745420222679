import { navigationComputed } from "@/state/helpers";
import { BlockClaimAddressInstance } from "./BlockClaimAddress";

export function mappedComputed() {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
  };
}

export const computed = {
  fake,
};

function fake(state: BlockClaimAddressInstance): string {
  return "";
}
