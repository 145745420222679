<script lang="ts">
import Vue from "vue";
import TitleBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/TitleBlock.vue";
import ButtonBlock from "@/components/shared/blocks/slaterGordonPcpLandingPage/ButtonBlock.vue";

export default Vue.extend({
  name: "AmIEligible",
  props: {
    buttonExists: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TitleBlock,
    ButtonBlock,
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/docIcon.png",
          description:
            "The terms of your agreement weren't clearly explained, and you were unaware that the broker or dealer would receive a commission for your deal.",
        },
        {
          id: 2,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/keysIcon.png",
          description:
            "You were unaware that they could set or adjust the interest rate on your deal, and the fees and charges were not explained to you upfront.",
        },
        {
          id: 3,
          img: "https://cdn.claimsgate.co.uk/complexLawLandingPage/fingerIcon.png",
          description:
            "You felt pressured or misled into agreeing to the deal and weren't informed about the relationships between lenders and brokers.",
        },
      ],
    };
  },
  methods: {
    emitRedirect() {
      this.$emit("emitRedirect"); // ! I AM GOING TO TELL MY PARENTS THAT I SHOULD BE REDIRECTED
    },
  },
});
</script>

<template>
  <div class="tw-flex tw-flex-col tw-w-full tw-p-4 lg:tw-p-16 tw-items-center" style="background-color: #f8f8f7">
    <div class="tw-max-w-[358px] md:tw-max-w-[554px] lg:tw-max-w-[1184px] tw-mx-auto tw-w-full">
      <TitleBlock
        title="Am I Eligible?"
        description="Consumers may seek redress if PCP agreement terms, including final lump sums, recurring payments, and usage limits, were not fully explained or were unsuitable."
      />

      <div class="tw-flex tw-flex-col xl:tw-flex-row tw-w-full tw-justify-between tw-gap-6">
        <div v-for="section in sections" :key="section.id" class="tw-mb-4 xl:tw-mb-0 tw-w-full xl:tw-flex-1">
          <div class="card mb-0 custom-card tw-w-full xl:tw-w-[378.67px] md:tw-h-[448px] tw-bg-white">
            <div class="card-body tw-p-0 tw-h-full">
              <div class="tw-flex tw-flex-col tw-items-center tw-py-6 tw-px-6 tw-h-full">
                <div
                  class="tw-flex tw-flex-col tw-items-center tw-font-semibold tw-mb-4 tw-w-full tw-text-center"
                  style="color: #0f1728"
                >
                  <img
                    :src="section.img"
                    loading="lazy"
                    class="tw-mb-2 tw-block"
                    :style="{ height: '80px', width: 'auto', margin: '0 auto' }"
                  />
                </div>
                <div class="tw-w-full tw-flex tw-justify-center tw-flex-1">
                  <p class="tw-mb-2 tw-text-xl tw-font-sans tw-text-center tw-w-full">
                    {{ section.description }}
                  </p>
                </div>
                <div v-if="buttonExists" class="tw-flex tw-justify-center tw-mt-4">
                  <ButtonBlock
                    text="Start My Free Check"
                    :onClick="emitRedirect"
                    :textSize="'sm'"
                    :fullWidth="false"
                    :backgroundColor="'#68B4BC'"
                    :textColor="'#FCFCFD'"
                    :hoverBackgroundColor="'#DA7F6E'"
                    :hoverTextColor="'#FCFCFD'"
                    :borderColor="'#68B4BC'"
                    :hoverBorderColor="'#DA7F6E'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  background-color: #ffffff;
  border-radius: 24px;
  border: 2px solid #c3dde0;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
